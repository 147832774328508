/* Full-width header styling */
.full-header {
    width: 100%;
    background-color: var(--color_5);
    padding: 20px;
    text-align: center;
    color: var(--color_3);
    position: fixed;
    top: 0;
    left: 0;
    z-index:900 !important;
}

.take-assessment-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

.take-assessment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.take-assessment-content {
    margin-top: 120px;
    width: 100%;
    max-width: 500px;
    text-align: left;
}

.question-text {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: var(--color_3); 
    text-align: center;
}

.answers-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    gap: 20px;
}

.answer-button {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    background: none;
    border: none;
    color: var(--color_4);
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
    text-decoration: none;
    transition: color 0.1s ease, transform 0.1s ease;
}

.answer-button:hover {
    color: var(--color_4-hover);
    transform: scale(1.1);
}


.recorded-answer {
    color: darkgray; /* Dark gray for the recorded answer */
}

.recorded-answer:hover {
    color: black; /* Go black when hovered */
}

.faded {
    opacity: 0;
    transition: opacity 0.25s ease;
}

.question-text,
.answers-container {
    transition: opacity 0.25s ease; 
}

.left-arrow, .right-arrow {
    position: fixed;
    bottom: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 10; /* Ensure arrows are above other elements */
    transition: opacity 0.3s ease-in-out; /* Smooth fade in/out */
}

.left-arrow {
    left: 20px; /* Bottom left corner */
}

.right-arrow {
    right: 20px; /* Bottom right corner */
}

/* Ensure that arrows fade in and out */
.left-arrow.hidden, .right-arrow.hidden {
    opacity: 0;
    pointer-events: none; /* Prevent clicks when hidden */
}

.left-arrow.visible, .right-arrow.visible {
    opacity: 1;
    pointer-events: auto;
}

/* Optional: Add hover effect for the buttons */
.left-arrow:hover, .right-arrow:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
    transition: transform 0.2s;
}
