.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--color_3);
  color: white;
  position: fixed; /* Keep the header fixed at the top */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  z-index: 1000; /* Higher z-index for the header */
}

.header-title {
  font-family: 'Forum', serif;
  font-size: 1.2rem;
  margin: 0;
}

.pillButton {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900 !important;
}

.dropdown-menu {
  font-family: 'Roboto Slab', serif;
  font-weight: 500;
  font-size:1 .23em;
  position: absolute; /* Position the dropdown menu absolutely */
  top: 100%; /* Position it right below the header */
  right: 0; /* Align to the right */
  background-color: var(--color_3); /* Background for the dropdown */
  border-bottom-left-radius: 8px; /* Rounded corner for bottom left */
  border-bottom-right-radius: 8px; /* Rounded corner for bottom right */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional: shadow for depth */
  width: 140px; /* Set width for the dropdown */
  z-index: 999; /* Lower than the header */
  padding: 1rem; /* Padding for dropdown items */
  animation: slideIn 0.3s; /* Animation for dropdown */
}

/* Optional styles for the menu items */
.menu-list {
  list-style: none;
  padding: 0;
}

.menu-item {
  margin: 1rem 0;
}

.menu-button {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size:1.1em;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: none; /* No text decoration */
  padding-left: 20px;
  transition: transform 0.2s ease; /* Transition for smooth scaling */
}


/* Hover effect for scaling */
.menu-button:hover {
  transform: scale(1.1); /* Scale up the button */
  font-weight: 600;
  color: var(--color_4);
}

/* Animation keyframes for dropdown */
@keyframes slideIn {
  from {
      transform: translateY(-10px); /* Start slightly above */
      opacity: 0; /* Start invisible */
  }
  to {
      transform: translateY(0); /* End at its position */
      opacity: 1; /* End visible */
  }
}

@keyframes slideOut {
  from {
      transform: translateY(0); /* Start slightly above */
      opacity: 1; /* Start invisible */
  }
  to {
      transform: translateY(-10); /* End at its position */
      opacity: 0; /* End visible */
  }
}
