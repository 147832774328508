.progress-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 7px;
    background-color: #7e7e7e; 
    z-index: 1000;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: var(--color_4);
    transition: width 0.3s ease-in-out; 
  }