.privacy-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 0;
}

.privacy-container {
  width: 80%;
  max-width: 600px;
  background-color: #fff; /* Optional: Background color */
  padding: 20px;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.privacy-container h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.privacy-container h2 {
  font-size: 22px;
}

.privacy-container {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  padding: 50px;
}

.privacy-title {
  margin-top: 50px;
  text-align: center;
}

      
.security-subtitle {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
  margin-top: 50px !important;
}
.security-text {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 1em;
  text-align: left;
  margin-top: 50px !important;
}

.outro-text {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  font-size: 1em;
  text-align: center;
  margin-top: 70px !important;
  margin-bottom: 150px;
}