.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  width: 100%; /* Ensures it can be responsive */
  padding: 20px;
}