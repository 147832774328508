.profile-setup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--color_5);
  padding: 20px;
  box-sizing: border-box;
}

.logo-container {
  margin-bottom: 20px;
}

.logo-container img {
  max-width: 300px;
  height: auto;
}

.profile-setup-form {
  background-color: var(--color_3);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-title {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--color_5);
  text-align: center;
}

.input-group {
  width: 100%;
  margin-bottom: 30px;
}

.profile-setup-form input {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.error-message {
  color: var(--color_7);
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

.couple-code-label {
  font-family: 'Roboto Slab', serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: var(--color_5) !important;
  margin-bottom: 8px; /* Adds some space between label and input */
  display: block; /* Ensures the label is above the input */
}