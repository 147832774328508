.email-confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--color_5);
}

.email-confirmation-box {
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 0px;
    background-color: transparent;
}

h2 {
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
    color: var(--color_3);
    text-align: center;
}

p {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
    color: var(--color_3);
    text-align: left;
}

.noOutline {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important; /* Ensure no border is applied */
    border-color: transparent
  }