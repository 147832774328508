/* Semitransparent background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

/* Fade-out effect */
.fade-out {
  opacity: 0;
  pointer-events: none; /* Ensures the overlay doesn't block taps during fade-out */
}

/* Central container */
.congratulations-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Heading */
.congratulations-container h1 {
  font-size: 1.5rem;
  margin: 20px 0;
}

/* Subtext */
.congratulations-container p {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin-top: 30px;
}

.congrats-title {
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  color: var(--color_4) !important;
  font-size: 1.7em !important;
}

.congrats-text {
  font-family: 'Roboto Slab', serif;
  font-weight: 400 !important;
  color: var(--color_5) !important;
  font-size: 1em !important;
}