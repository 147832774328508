body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Forum Font */
@font-face {
  font-family: 'Forum';
  src: url('./assets/fonts/Forum.woff2') format('woff2'),
       url('./assets/fonts/Forum.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Roboto Slab Font with different weights */
@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-style: normal;
}

/* Add this to force browser to use a fallback if Roboto Slab is not available */
.font-weight-test-fallback { font-family: 'Roboto Slab', Arial, sans-serif; font-weight: 900; }

/* Apply globally */
body {
  font-family: 'Roboto Slab', serif;
  font-weight: 400; /* Regular weight */
  line-height: 1.6; /* Optional: Add better line spacing */
}

/* Heading styles */
h1, h2 {
  font-family: 'Roboto Slab', serif;
  font-weight: 700; /* Bold headings */
}

/* Lighter weight text example */
p.light-text {
  font-family: 'Roboto Slab', serif;
  font-weight: 100; /* Thin weight */
}

/* Extra bold headings */
h3.extra-bold {
  font-family: 'Roboto Slab', serif;
  font-weight: 900; /* Extra bold weight */
}

.pillButton {
  font-family: 'Roboto Slab', serif !important;
  font-weight: 900 !important;
  font-size: 18px !important;
  width: var(--button-width, fit-content) !important;
  max-width: 100% !important;
  padding: 12px 20px !important;
  margin-top: 30px !important;
  border-radius: 50px !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  overflow: hidden !important;
  text-align: center !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  border: 2px solid var(--color_4) !important;
}

/* Width classes */
.pillButton.narrow { --button-width: 25% !important; }
.pillButton.medium { --button-width: 50% !important; }
.pillButton.wide { --button-width: 75% !important; }

/* Theme classes */
.pillButton.light {
  color: var(--color_1) !important;
}

.pillButton.dark {
  color: var(--color_3) !important;
}

/* Hover styles */
.pillButton:hover {
  background-color: var(--color_4) !important;
}

.pillButton.light:hover,
.pillButton.dark:hover {
  color: #ffffff !important; /* White text on hover for both light and dark */
}

/* Triangle styles */
.pillButton .triangle {
  display: inline-block !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 6px solid transparent !important;
  border-bottom: 6px solid transparent !important;
  border-left: 10px solid currentColor !important;
  margin-left: 7px !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  top: 1.5px !important; /* Adjust this value to move the triangle up or down */
}

.iconBg {
  margin-right: 8px; /* Adjust as needed */
}

.icon {
  font-size: 1.5rem;
}

.text {
  vertical-align: middle;
}


:root {
  --color_1: rgba(255, 255, 255, 1); /* white */
  --color_2: rgba(143, 198, 145, 1); /* sage */
  --color_3: rgba(34, 34, 34, 1); /* dark bg */
  --color_4: rgba(255, 159, 49, 1); /* accent orange */
  --color_4_hover: rgba(255, 195, 91, 1); /* Brighter, paler accent orange for hover */
  --color_5: rgba(253, 250, 246, 1); /* cream bg */
  --color_6: rgba(0, 0, 0, 1); /* black */
  --color_7: rgba(255, 102, 102, 1);
  --color_8: rgba(221, 216, 209, 1); 
  --color_9: rgb(103, 103, 103); 
  --color_10: rgb(198, 198, 198);
  --color_11: rgb(128, 176, 129);
  --color_12: rgb(150, 150, 150); 
  --color_13: rgb(70, 70, 70); 
  
}

