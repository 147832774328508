/* assessment-block.css */
.assessment-block {
    background-color: #f0f0f0;
    padding: 20px;
}

.columns.single-column {
    display: block;
  }
  
  .column.full-width {
    width: 100%;
  }

.custom-brand-hr {
    border: 0; /* Remove the default border */
    height: 2px; /* Set the thickness of the line */
    background-color: var(--color_4); /* Change to your desired color */
}

.custom-faint-hr {
    border: 0; /* Remove the default border */
    height: 2px; /* Set the thickness of the line */
    background-color: var(--color_10); /* Change to your desired color */
    margin:30px;

}

.columns {
    display: flex;
    justify-content: space-between;
}

.column {
    width: 48%;
}

.darker-gray-block {
    height: 200px;
    position: relative;
    background-color: #444;
    border-radius: 10px;
    overflow: hidden;
    max-width: 150px;
    margin: auto;
}

.orange-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0; 
    background-color: var(--color_2);
    transition: height 1s ease-out 0.2s, opacity 1s ease-out; 

}

.name-label {
    font-family: 'Roboto Slab', serif !important;
    font-weight: 500 !important;
    text-align: center;
    margin-top: 10px;
}

.aspect-title {
    text-align: center;
    margin-bottom: 10px;
}

.aspect-description {
    margin-bottom: 20px;
    font-family: 'Roboto Slab', serif !important;
    font-weight: 300;
}

.assessment-description {
    margin-bottom: 30px;
    font-family: 'Roboto Slab', serif !important;
    font-weight: 300;
}

.aspect-score {
    margin-bottom: 10px;
}

.score-highlight {
    color: var(--color_11); 
    font-weight: 500;
}

.aspect-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.aspect-header span {
    font-size: clamp(8px, 1.5vw, 16px); 
}

.score-description {
    color: gray;
}

.aspect-name {
    color: var(--color_3);
    font-family: 'Roboto Slab', serif !important;
    font-weight: 600;
    font-size: 1em !important;
}

.aspect-score-description {
    color: var(--color_11);
    font-family: 'Roboto Slab', serif !important;
    font-weight: 600;
    font-size: 1em !important;
}

.aspect-progress-bar {
    height: 10px;
    background-color: #444;
    position: relative;
    overflow: hidden;
}

.aspect-progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0; 
    background-color: var(--color_2);
    transition: width 0.75s ease-out 0.15s, opacity 0.75s ease-out; 
}

.aspect-details {
    color: var(--color_3);
    font-family: 'Roboto Slab', serif !important;
    font-weight: 300;
    font-size: .7em;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.aspect-confidence-value {
    color: var(--color_9) !important;
    font-family: 'Roboto Slab', serif !important;
    font-weight: 300 !important;
    font-size: .7em !important;
}

.dark-block {
    font-family: 'Roboto Slab', serif !important;
    font-weight: 300;
    width: 100%;
    background-color: #f0f0f0 !important;
    margin: 40px 0;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;
}

.orange-title {
    font-family: 'Roboto Slab', serif !important;
    color: var(--color_4) !important;
    font-weight: 600;
    font-size: clamp(1.5rem, 5vw, 3rem); 
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
    .orange-title {
        font-size: clamp(1.5rem, 4vw, 2rem);
    }
}

