/* components/loader.css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent white overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Lower than Popup to allow Popup to appear on top */
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-text {
  margin-top: 20px;
  font-size: 18px;
  color: #333333;
}