/* Center the form and add padding */
.login-form-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--color_5)
}

/* Style the form */
.login-form {
  margin: 0;
  background-color: var(--color_3);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  margin-bottom: 20px; /* Adjust as needed */
}


/* Style form headings */
.login-form h2 {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  font-size: 16px;
  /* Adjust size if needed */
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Style the input fields */
.login-form input {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  padding: 12px;
  margin-bottom: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.toggle-button {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 16px !important;
  margin-top: 20px;
  background: none;
  border: none;
  color: var(--color_3);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.toggle-button:hover {
  color: var(--color_4);
}

.logo-container {
  margin-bottom: 40px;
  margin-top: 40px;
}

.logo-container img {
  max-width: 300px;
  display: block;
  margin: 0 auto;
}

.forgot-password {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  font-size: 14px;
  color: var(--color_3);
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
}

.login-title {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-bottom: 30px !important;
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  font-size: 14px;
  color: var(--color_5) !important;
  cursor: pointer;
}

.forgot-password:hover {
  color: var(--color_4);
  text-decoration: none;
}

.auth-title {
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  color: var(--color_5) !important;
  font-size: 1.3em !important;
  font-weight: 600;
}

.error-message {
  color: var(--color_7);
  font-size: 14px;
  margin-bottom:20px;
  color: var(--color_7);
}

.wide {
  margin-top: 20px !important;
}

.why {
  max-width: 380px;
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  font-size: 15px !important;
  text-align: left;
  color: white;
}

.why-focused {
  max-width: 380px;
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  font-size: 15px !important;
  text-align: left;
  color: var(--color_4); 
}

.custom-hr {
  border: 0; 
  height: 1px; 
  background-color: var(--color_4); 
  width: 100%;
  margin-top:20px;
}

@media (max-width: 768px) { /* Target mobile devices */
  .why {
      margin-bottom: 400px; /* Adjust as needed */
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--color_3);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
}

.modal-content h2 {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--color_5);
  text-align: center;
}

.modal-content input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Roboto Slab', serif;
  box-sizing: border-box;
}

.success-message {
  color: green;
  margin: 10px 0;
  text-align: center;
}

.two-column-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  box-sizing: border-box;
}

.video-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  height: 100vh;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50%;
}

@media (max-width: 768px) {
  .two-column-layout {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    padding-top: 140px;
    padding-bottom: 120px; /* Add significant bottom padding for keyboard */
  }

  .video-column, .form-column {
    max-width: 100%;
    width: 100%;
  }

  .video-column {
    height: auto;
    margin-bottom: 20px;
  }
}
