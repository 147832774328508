/* assessment-builder.css */

/* Container Styles */

body {
  background-color: var(--color_5);
  margin: 0; 
  padding: 0; 
}

.container {
  text-align: center;
  background-color: var(--color_5) !important;
}

.title {
  margin-top: 150px;
}

.textarea {
  width: 50%;
  max-width: 1024px;
  height: 100px;
  margin-top: 20px;
}

.button-container {
  margin-top: 30px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
}

.message {
  margin-top: 30px;
  text-align: center;
}

/* Edit Container Styles */
.edit-container {
  width: 100%;
  margin-top: 30px;
  padding: 30px 0;
}

.edit-container-questions {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 100px;
  padding: 30px 0;
}

.edit-inner-container {
  background-color: var(--color_8);
  width: 90%;
  max-width: 975px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 25px;
  position: relative;
}

.delete-button-container {
  position: absolute;
  top: 25px;
  right: 25px;
}

.delete-button {
  padding: 10px 20px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-group {
  margin-top: 20px;
  text-align: left;
  width: 75%;
  margin: auto;
}

.form-label {
  font-family: 'Roboto Slab', serif;
  font-weight: 600 !important;
  font-size: 16px;
  color: var(--color_3);
}

.input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.checkbox {
  margin-left: 10px;
  transform: scale(1.5);
}

.update-button {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 16px;
}

.update-message {
  margin-top: 20px;
  text-align: center;
  color: green;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup {
  background-color: #000;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  margin: 100px auto;
  text-align: center;
}

.popup-text {
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-weight: 400 !important;
  font-size: 16px;
  color: var(--color_5);
  padding: 20px;
  margin: auto;
  max-width:75%;
}

.popup-label {
  display: block;
  text-align: left;
  margin-bottom: 15px;
  color: #ccc;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-weight: 200 !important;
  font-size: 14px;
  color: var(--color_5);
}

.h2-white {
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  font-size: 20px;
  color: var(--color_5);
}

.tinyLabel {
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-weight: 200 !important;
  font-size: 14px;
  color: var(--color_5);
}

.delete-input-container {
  display: flex;
  align-items: center;
}

.delete-input {
  flex: 1;
  padding: 8px;
}

.trash-button {
  margin-left: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.trash-icon {
  width: 30px;
  height: 30px;
}

.cancel-button {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right:30px !important;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
}

.inner-cancel-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  margin-right:30px !important;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Question Editing Styles */
.question-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right:20px;
  margin-left:20px;
  background-color: var(--color_5);
  border-radius: 5px;
  padding: 20px;
}

.drag-handle {
  cursor: grab;
  font-size: 24px;
  user-select: none;
  position: absolute;
  margin-top: -10px;
}

.question-content {
  flex: 1;
}

.question-label {
  margin-bottom: 10px;
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  font-size: 16px;
  color: var(--color_3);
}

.question-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-family: 'Roboto Slab', serif;
  font-weight: 400 !important;
  font-size: 14px;
  color: var(--color_3);
}

.answer-row {
  display: flex;
  align-items: center;
}

.answer-input {
  flex: 1;
  padding: 8px;
  margin-right: 15px;
  box-sizing: border-box;
  font-family: 'Roboto Slab', serif;
  font-weight: 400 !important;
  font-size: 14px;
  color: var(--color_3);
}

.question-buttons {
  display: flex;
  align-items: center;
}

.save-button {
  background-color: #4caf50;
  color: #fff;
  padding: 6px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-question-button {
  background-color: #f44336;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Add Question Button Styles */
.add-question-button-container {
  position: absolute;
  top: 25px;
  right: 25px;
}

.add-question-button {
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  color: #fff;
  border-radius: 50%;
  border: none;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.popup-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-family: 'Roboto Slab', serif;
  font-weight: 400 !important;
  font-size: 14px;
  color: var(--color_3);
}

.popup-button-container {
  display: flex;
  justify-content: space-between;
}

.popup-cancel-button {
  background-color: #f44336;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  font-size: 20px;
  color: var(--color_5);
  margin: 20px;
}

.popup-save-button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto Slab', serif;
  font-weight: 700 !important;
  font-size: 20px;
  color: var(--color_5);
  margin: 20px;
}

.quick-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px; 
  font-family: 'Roboto Slab', serif !important;
  color: "white" !important;
  font-weight: 300;
}

.assessment-link {
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  color: #333;
  text-decoration: none;
  font-family: 'Roboto Slab', serif !important;
  color: "white" !important;
  font-weight: 500;
}

.assessment-link:hover {
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  text-decoration: underline;
  color: var(--color_4);
  text-decoration: none;
  font-weight: 700;
}

.edit-hr {
  width: 50%;
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: lightGray !important;
  opacity: 50%;
}

.support-content {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.section {
    margin-bottom: 40px;
}

.section h2 {
    color: var(--color_5);
    margin-bottom: 20px;
    font-family: 'Roboto Slab', serif;
}

.user-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.user-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-info {
    margin-bottom: 15px;
}

.user-info p {
    margin: 8px 0;
    font-family: 'Roboto Slab', serif;
}

.user-info p strong {
    display: inline-block;
    margin-bottom: 4px;
}

.user-info p br + * {
    display: inline-block;
    margin-top: 4px;
    margin-left: 4px;
}

.user-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    border-top: 1px solid #eee;
}

.text-button {
    background: none;
    border: none;
    color: var(--color_4);
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    cursor: pointer;
    padding: 0 8px;
}

.text-button:hover {
    text-decoration: underline;
}

.separator {
    color: #666;
    font-size: 14px;
    user-select: none;
}

.search-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.search-input {
    width: 100%;
    max-width: 500px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Roboto Slab', serif;
}

@media (max-width: 768px) {
    .user-list {
        grid-template-columns: 1fr;
    }

    .user-card {
        margin: 10px;
    }
}

.edit-user-section {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--color_8);
    border: 1px solid #eee;
}

.edit-user-section h3 {
    margin-bottom: 15px;
    color: var(--color_3);
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
}

.edit-field {
    margin-bottom: 15px;
}

.edit-field label {
    display: block;
    margin-bottom: 8px;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--color_3);
}

.edit-field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    background-color: white;
}

.edit-field input:focus {
    outline: none;
    border-color: var(--color_4);
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #eee;
}

.modal-content {
    background-color: var(--color_3);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    margin: 20px auto;
}

.modal-content h2 {
    margin-bottom: 20px;
    color: white;
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    text-align: center;
}

.user-save-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    overflow-y: auto;
    z-index: 1000;
}

.section-title {
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
}

.section-title .highlight {
    color: var(--color_4);
}

.section-title .secondary {
    color: var(--color_3);
}

.users-section {
    background-color: var(--color_3);
    padding: 40px 0;
    margin-top: 40px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.users-section .section-title {
    color: white;
    padding: 0 20px;
}

.users-section .user-list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.radio-group {
    margin-top: 20px;
}

.radio-options {
    display: flex;
    gap: 20px;
    margin-top: 8px;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    color: var(--color_5);
}

.radio-label input[type="radio"] {
    margin: 0;
    width: auto;
    cursor: pointer;
}

/* Toggle Switch Styles */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top: 8px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.toggle-switch input:checked + .toggle-slider {
    background-color: var(--color_4);
}

.toggle-switch input:checked + .toggle-slider:before {
    transform: translateX(26px);
}

.toggle-label {
    margin-left: 70px;
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    color: var(--color_3);
}

.warning-text {
    color: var(--color_7);
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    margin: 15px 0;
    padding: 10px;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 4px;
}

.delete-confirm-button {
    color: var(--color_7) !important;
}

.delete-confirm-button:disabled {
    color: #999 !important;
    cursor: not-allowed;
}

.modal-content p {
    font-family: 'Roboto Slab', serif;
    font-size: 14px;
    margin: 10px 0;
    color: var(--color_5);
}

.modal-content p strong {
    color: var(--color_4);
}

.auth-users-section {
    background-color: var(--color_8);
    padding: 40px 0;
    margin-top: 0;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.auth-users-section .section-title {
    color: var(--color_3);
    padding: 0 20px;
}

.auth-users-section .user-list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.auth-users-section .user-card {
    background-color: var(--color_3);
}

.auth-users-section .user-info p {
    color: var(--color_5);
}

.auth-users-section .user-info p strong {
    color: var(--color_4);
}

.auth-users-section .text-button {
    color: var(--color_5);
}

.auth-users-section .text-button:hover {
    color: var(--color_4);
}

.auth-users-section .separator {
    color: var(--color_5);
}