

.icon {
    width: 40px;
    height: 40px !important;
}

.custom-heading {
    font-family: 'Forum', serif;
    font-size: 2rem;
    margin-bottom: 30px;
    margin-top:125px;
}

.assessment-results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width:100%;
    min-width: 350px;
    align-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.results-content {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 20px;
    min-width: 350px;
}

.dark-results-block {
    font-family: 'Roboto Slab', serif !important;
    font-weight: 300;
    width: 100%;
    background-color: #f0f0f0 !important;
    margin: auto;
    padding: 25px;
    margin-top: 0px;
    margin-bottom: 0px;
    min-width: 330px !important;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;
}

.darker-results-block {
    font-family: 'Roboto Slab', serif !important;
    color: "white" !important;
    font-weight: 300;
    width: 100%;
    background-color: #555555 !important;
    margin: 20px 0;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;
}

.clear-results-block {
    font-family: 'Roboto Slab', serif !important;
    color: var(--color_3) !important;
    font-weight: 300;
    width: 100%;
    background-color: transparent;
    padding: 25px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;
}

.no-spouse-block {
    width: 100%;
    background-color: var(--color_13) !important;
    margin: 40px 0;
    padding-top: 35px;
    padding-bottom: 35px;
}

.no-spouse-assessment-container p {
    max-width: 400px;
    margin: auto;
    box-sizing: border-box;
}

.no-spouse-note-title {
    font-family: 'Roboto Slab', serif !important;
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    color: var(--color_4) !important;
}

.no-spouse-note-text {
    font-family: 'Roboto Slab', serif !important;
    font-size: .9em;
    font-weight: 400;
    text-align: left;
    color: white !important;
}

.areas-list p {
    font-family: 'Roboto Slab', serif !important;
    font-weight: 500 !important;
    width: 90%;
    max-width: 500px !important;
    margin: 0 auto;
    text-align: left;
    padding: 10px   
}

.slab-heavy {
    font-family: 'Roboto Slab', serif !important;
    font-weight: 500 !important;
}

.slab-light {
    font-family: 'Roboto Slab', serif !important;
    font-weight: 350 !important;
}

.darker-block-title {
    font-family: 'Roboto Slab', serif !important;
    color: var(--color_1) !important;
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
    align: top;
}

.darker-title {
    font-family: 'Roboto Slab', serif !important;
    color: var(--color_3) !important;
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
}

.columns {
    display: flex; /* Create a flex container for the columns */
    justify-content: space-between; /* Space out the columns */
}

.left-column,
.right-column {
    width: 50%; /* Each column takes half of the container */
}

.gray-block {
    width: 100px; /* Set the width of the gray block */
    height: 200px; /* Set the height of the gray block */
    background-color: darkgray; /* Set background color to dark gray */
    position: relative; /* Allow absolute positioning for the inner block */
    padding: 20px; /* Add padding around the block */
}

.orange-progress {
    background-color: orange; /* Set background color to orange */
    position: absolute; /* Position it absolutely within the gray block */
    bottom: 0; /* Align it to the bottom */
    left: 0; /* Align it to the left */
    right: 0; /* Align it to the right */
    transition: height 0.5s ease-out; /* Animate the height change */
}

.half-transparent {
    background-color: rgba(169, 169, 169, 0.5); /* Half transparent gray */
}