.assessment-container-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* Ensure container takes full width */
}

.assessment-container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  /* Ensure container takes full width */
  max-width: 800px;
  /* Maximum width */
}

.done-container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  min-width: 350px;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
  box-sizing: border-box;
}

.completed-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* Ensure container takes full width */
  max-width: 800px;
  /* Maximum width */
}

.no-assessments {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
  margin-top: 150px;
}

/* Adjust width for smaller screens */
@media (max-width: 900px) {
  .assessment-container-inner {
    width: 80%;
    /* Width for screens <= 900px */
  }
}

@media (max-width: 800px) {
  .assessment-container-inner {
    width: 80%;
    /* Width for screens <= 800px */
  }
}

@media (max-width: 700px) {
  .assessment-container-inner {
    width: 80%;
    /* Width for screens <= 700px */
  }
}

@media (max-width: 600px) {
  .assessment-container-inner {
    width: 85%;
    /* Width for screens <= 600px */
  }
}

.assessment-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 8px;
  /* Keep rounded corners */
  margin-bottom: 20px;
  transition: background-image 0.3s ease;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  opacity: 1;
}

.assessment-row.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.assessment-row img {
  height: 64px;
  margin-right: 1rem;
}

.assessment-row h3 {
  font-size: 1.3rem;
  margin: 0;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  color: var(--color_5);
}

.icon {
  width: 40px;
  height: 40px !important;
}

@media (max-width: 768px) {
  .assessment-row {
    width: 90%;
  }
}

.home-main {
  text-align: center;
  padding: 2rem;
  margin: 100px;
}

@media (max-width: 768px) {
  .home-main {
    margin: 15px;
    margin-top: 100px;
  }
}